import store from '@/state/store.js';
export default {
  data() {
    return {
      buttonLoading: false
    };
  },
  props: {
    payload: {
      type: Object,
      default: () => {}
    },
    deleteVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    deleteVisibleData: {
      get() {
        return this.deleteVisible;
      },
      set(val) {
        this.$emit('update:deleteVisible', val);
      }
    }
  },
  methods: {
    submitDelete() {
      this.buttonLoading = true;
      store.dispatch('admin/reporting/deleteDebitors', {
        payment_id: this.payload.row.payment_id,
        user_id: this.payload.row.user_id
      }).then(res => {
        this.$notify({
          title: 'Success',
          message: 'Debotor cleared',
          type: 'success'
        });
        this.$emit('refresh');
        this.buttonLoading = false;
        this.deleteVisibleData = false;
      }).catch(err => {
        this.$notify({
          title: 'Error',
          message: 'Something went wrong',
          type: 'error'
        });
        this.buttonLoading = false;
        this.deleteVisibleData = false;
      });
    }
  }
};