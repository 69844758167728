var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('el-dialog', {
    attrs: {
      "title": "Confirm Deletion",
      "visible": _vm.deleteVisibleData
    },
    on: {
      "update:visible": function ($event) {
        _vm.deleteVisibleData = $event;
      }
    }
  }, [_c('p', [_vm._v("Are you sure you want to delete this debt?")]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.deleteVisibleData = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "danger",
      "loading": _vm.buttonLoading
    },
    on: {
      "click": _vm.submitDelete
    }
  }, [_vm._v("Confirm")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };