import store from '@/state/store.js';
export default {
  data() {
    return {
      confirmationModal: false,
      buttonLoading: false,
      chartedList: [],
      formData: {
        amount_to_pay: '',
        payment_method: '',
        notes: ''
      },
      rules: {
        amount_to_pay: [{
          required: true,
          message: "Amount to pay is required.",
          trigger: "blur"
        }],
        payment_method: [{
          required: true,
          message: "Payment method is required",
          trigger: "blur"
        }],
        notes: [{
          required: true,
          message: "Notes is required",
          trigger: "blur"
        }]
      }
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    payload: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    checkEnter() {
      this.$refs['makePaymentForm'].validate(valid => {
        if (valid) {
          this.confirmationModal = true;
        } else {
          return false;
        }
      });
    },
    submitNewData() {
      let payload = {
        paid_amount: this.formData.amount_to_pay,
        payment_method: this.formData.payment_method,
        notes: this.formData.notes,
        user_id: this.payload.row.user_id,
        debtor_id: this.payload.row.id
      };
      this.buttonLoading = true;
      store.dispatch('admin/reporting/clearDebitors', payload).then(res => {
        this.buttonLoading = false;
        this.confirmationModal = false;
        this.dialogVisibleData = false;
        this.$emit('refresh');
      });
    }
  },
  computed: {
    dialogVisibleData: {
      get() {
        return this.dialogVisible;
      },
      set(val) {
        this.$emit('update:dialogVisible', val);
      }
    },
    tableData: {
      get() {
        return this.payload == undefined ? {} : this.payload;
      },
      set(val) {
        this.$emit('update:payload', val);
      }
    }
  },
  mounted() {
    store.dispatch('admin/reporting/getChartAccounts').then(res => {
      this.chartedList = res;
    });
  }
};