import "core-js/modules/es.array.push.js";
import store from '@/state/store.js';
import appConfig from '@/app.config';
import makePayment from './makePayment.vue';
import DeleteMainDebt from './DeleteMainDebt.vue';
import makePaymentIcon from './makePaymentIcon.vue';
export default {
  page: {
    title: 'Debtors list',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  components: {
    makePayment,
    DeleteMainDebt,
    makePaymentIcon
  },
  data() {
    return {
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 20,
      total: 0,
      search: '',
      dialogVisible: false,
      deleteVisible: false,
      dataForModal: {},
      status: ""
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
    },
    openPaymentModal({
      row,
      key,
      type
    }) {
      if (type === 'delete') {
        this.deleteVisible = true;
        this.dataForModal = {
          row,
          key,
          type
        };
        return;
      } else if (type === 'payment') {
        this.dialogVisible = true;
        this.dataForModal = {
          row,
          key,
          type
        };
        return;
      }
    },
    refetchData() {
      this.loading = true;
      store.dispatch('admin/reporting/getDebtorsList').then(res => {
        this.tableData = res;
        this.$notify({
          title: 'Success',
          message: 'Debotor cleared',
          type: 'success'
        });
      }).finally(() => this.loading = false);
    },
    openNewLink({
      row,
      key
    }) {
      store.dispatch('admin/reporting/getSpecificDebtor', row.payment_id).then(data => {
        this.$router.push(`/admin/finance/debtors_list/${row.payment_id}`);
      });
    }
  },
  computed: {
    searchingTableData() {
      if (!this.search) {
        this.total = this.tableData.length;
        return this.tableData;
      }
      this.page = 1;
      return this.tableData.filter(data => data.user.firstname.toLowerCase().includes(this.search.toLowerCase()) || data.user.lastname.toLowerCase().includes(this.search.toLowerCase()));
    },
    displayData() {
      this.total = this.searchingTableData.length;
      return this.searchingTableData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
    }
  },
  mounted() {
    this.loading = true;
    store.dispatch('admin/reporting/getDebtorsList').then(res => this.tableData = res).finally(() => this.loading = false);
  }
};