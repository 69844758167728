var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "main-dialogs-container"
  }, [_c('div', {
    staticClass: "initial-modal"
  }, [_c('el-dialog', {
    attrs: {
      "title": "Make Payment",
      "visible": _vm.dialogVisibleData,
      "width": "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisibleData = $event;
      }
    }
  }, [_c('el-form', {
    ref: "makePaymentForm",
    attrs: {
      "model": _vm.formData,
      "rules": _vm.rules,
      "label-position": "left"
    },
    nativeOn: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.checkEnter.apply(null, arguments);
      }
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Amount to pay",
      "prop": "amount_to_pay"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Enter amount"
    },
    model: {
      value: _vm.formData.amount_to_pay,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "amount_to_pay", $$v);
      },
      expression: "formData.amount_to_pay"
    }
  })], 1), _c('el-form-item', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column"
    },
    attrs: {
      "label": "Payment Method",
      "prop": "payment_method"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "filterable": "",
      "placeholder": "Select payment method"
    },
    model: {
      value: _vm.formData.payment_method,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "payment_method", $$v);
      },
      expression: "formData.payment_method"
    }
  }, _vm._l(_vm.chartedList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "value": item.name,
        "label": item.name
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Notes",
      "prop": "notes"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Add a note"
    },
    model: {
      value: _vm.formData.notes,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "notes", $$v);
      },
      expression: "formData.notes"
    }
  })], 1), _c('el-button', {
    staticStyle: {
      "width": "50%",
      "margin": "0 auto",
      "display": "flex",
      "justify-content": "center"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.checkEnter
    }
  }, [_vm._v("Submit")])], 1)], 1)], 1), _c('div', {
    staticClass: "confirmation-modal"
  }, [_vm.confirmationModal ? _c('el-dialog', {
    attrs: {
      "visible": _vm.confirmationModal,
      "title": 'Confirmation',
      "width": "40%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.confirmationModal = $event;
      }
    }
  }, [_c('div', {
    staticClass: "heading"
  }, [_c('p', [_vm._v("Are you sure you want to make payment?")])]), _c('div', {
    staticClass: "preview"
  }, [_c('p', {
    staticStyle: {
      "font-size": "11px"
    }
  }, [_vm._v("Amount to pay:   "), _c('strong', {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v(_vm._s(_vm.formData.amount_to_pay))])]), _c('p', {
    staticStyle: {
      "font-size": "11px"
    }
  }, [_vm._v("Payment Method:   "), _c('strong', {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v(_vm._s(_vm.formData.payment_method))])]), _c('p', {
    staticStyle: {
      "font-size": "11px"
    }
  }, [_vm._v("Note:   "), _c('strong', {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v(_vm._s(_vm.formData.notes))])]), _c('p', {
    staticStyle: {
      "font-size": "11px"
    }
  }, [_vm._v("User ID:   "), _c('strong', {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v(_vm._s(_vm.tableData.row.user_id))])]), _c('p', {
    staticStyle: {
      "font-size": "11px"
    }
  }, [_vm._v("Debtor ID:   "), _c('strong', {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v(_vm._s(_vm.tableData.row.id))])])]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.confirmationModal = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "danger",
      "loading": _vm.buttonLoading
    },
    on: {
      "click": _vm.submitNewData
    }
  }, [_vm._v("Confirm")])], 1)]) : _vm._e()], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };